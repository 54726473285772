<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
    keyContact: any
    collaboration: Boolean
}

const props = defineProps<Props>()
const localKeyContact = computed(() => props.keyContact)
const translation = computed(() =>
    localKeyContact.value.translations?.length
        ? localKeyContact.value.translations[0]
        : { name: 'no translations name' }
)
const fullname = computed(() => translation.value.name)
</script>

<template>
    <div v-if="localKeyContact" class="person-page__card">
        <div class="person-card__image-wrapper">
            <div>
                <AppNuxtLinkLocale
                    :to="{
                        path: `/employees/${localKeyContact.slug}`
                    }"
                >
                    <img
                        :src="localKeyContact.imageLink"
                        alt="person image"
                        class="person-card__img"
                    />
                </AppNuxtLinkLocale>

                <div class="person-card__fullname-wrapper">
                    <AppNuxtLinkLocale
                        :to="{
                            path: `/employees/${localKeyContact.slug}`
                        }"
                        class="person-card__name"
                        :class="{
                            'collaboration-keycontact': collaboration
                        }"
                        >{{ fullname }}
                    </AppNuxtLinkLocale>
                    <div
                        style="
                            display: flex;
                            justify-content: space-between;
                        "
                    >
                        <p
                            style="font-size: 16px"
                            :class="{
                                'collaboration-keycontact':
                                    collaboration
                            }"
                        >
                            {{ translation.jobTitle }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="person-page__card-wrapper">
            <div class="person-card__position">
                <AppNuxtLinkLocale
                    :to="{
                        path: `/${localKeyContact.country?.slug}/regions`
                    }"
                    class="person-card__location"
                    style=""
                    :class="{
                        'collaboration-keycontact': collaboration
                    }"
                >
                    <span>
                        <SvgoMapMarker />{{
                            localKeyContact.officeCountry
                        }}
                    </span>
                </AppNuxtLinkLocale>
                <div
                    v-if="translation.city"
                    style="
                        display: flex;
                        font-size: 18px;
                        gap: 8px;
                        white-space: nowrap;
                        background: rgba(59, 130, 246, 0.35);
                    "
                >
                    <SvgoMapMarker />
                    {{ translation.city }}
                </div>
            </div>
            <div
                class="person-card__numbers"
                :style="
                    collaboration
                        ? `margin-bottom: 4px; line-height: 25px`
                        : ''
                "
            >
                <div class="person-card__number">
                    <SvgoPhone />
                    <a
                        style="font-size: 18px"
                        :href="`tel:${localKeyContact.firstPhoneNumber}`"
                    >
                        {{ localKeyContact.firstPhoneNumber }}</a
                    >
                </div>
                <div
                    v-if="localKeyContact.secondPhoneNumber"
                    class="person-card__number"
                >
                    <SvgoPhone />

                    <a
                        style="font-size: 18px"
                        :href="`tel:${localKeyContact.secondPhoneNumber}`"
                        >{{ localKeyContact.secondPhoneNumber }}</a
                    >
                </div>
            </div>
            <a
                class="person-card__number"
                :href="`mailto:${localKeyContact.firstEmail}`"
                target="_blank"
                @click.stop
            >
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M16 0.25H2C1.53668 0.252625 1.09309 0.437842 0.765464 0.765464C0.437842 1.09309 0.252625 1.53668 0.25 2V14C0.252625 14.4633 0.437842 14.9069 0.765464 15.2345C1.09309 15.5622 1.53668 15.7474 2 15.75H16C16.4633 15.7474 16.9069 15.5622 17.2345 15.2345C17.5622 14.9069 17.7474 14.4633 17.75 14V2C17.7474 1.53668 17.5622 1.09309 17.2345 0.765464C16.9069 0.437842 16.4633 0.252625 16 0.25ZM2 1.75H16C16.0663 1.75 16.1299 1.77634 16.1768 1.82322C16.2237 1.87011 16.25 1.9337 16.25 2V3.54L9 7.16L1.75 3.54V2C1.75 1.9337 1.77634 1.87011 1.82322 1.82322C1.87011 1.77634 1.9337 1.75 2 1.75ZM16 14.25H2C1.9337 14.25 1.87011 14.2237 1.82322 14.1768C1.77634 14.1299 1.75 14.0663 1.75 14V5.21L8.66 8.67C8.76556 8.7228 8.88197 8.75029 9 8.75029C9.11803 8.75029 9.23444 8.7228 9.34 8.67L16.25 5.21V14C16.25 14.0663 16.2237 14.1299 16.1768 14.1768C16.1299 14.2237 16.0663 14.25 16 14.25Z"
                        fill="#3b82f6"
                    />
                </svg>
                {{ $t('emailMe') }}
            </a>
        </div>
    </div>
</template>
